import React, { useContext, useEffect, useState, useCallback } from 'react';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';

// Contexts
import { WebSocketContext } from '../context/WebSocketContext';
import { AuthContext } from '../context/AuthContext';

// Utils
import { compressImage } from '../utils/imageCompression';
import { apiFetch } from '../utils/api';

// Sections
import FeedSection from './feed/FeedSection';
import LiveFeedSection from './feed/LiveFeedSection';

// MindMap component
import MindMap from './mindmap/MindMap';

function Dashboard() {
  const navigate = useNavigate();

  // WebSocket context
  const {
    notifications,
    setNotifications,
    activities,
    setActivities,
    feedItems,
    setFeedItems,
    liveFeedItems,
    setLiveFeedItems,
    currentUserId,
    setCurrentUserId,
    cacheBustValues,
  } = useContext(WebSocketContext);

  // Auth context
  const { user } = useContext(AuthContext);

  // Local State
  const [searchTerm, setSearchTerm] = useState('');
  const [showActivitiesPopup, setShowActivitiesPopup] = useState(false);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);

  // Tabs
  const [activeTab, setActiveTab] = useState('feed');

  // Feed states
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostImageFile, setNewPostImageFile] = useState(null);
  const [newPostImagePreview, setNewPostImagePreview] = useState(null);
  const [isPosting, setIsPosting] = useState(false);

  // Comments
  const [visibleComments, setVisibleComments] = useState({});

  // Messages
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(null);

  // Track accepted connections for the MindMap
  const [connectedUsers, setConnectedUsers] = useState([]);

  /**
   * --------------------------------------------------
   * Set Current User ID from Auth
   * --------------------------------------------------
   */
  useEffect(() => {
    if (user) {
      if (user.id !== undefined && user.id !== null) {
        const userId = Number(user.id);
        if (!isNaN(userId)) {
          setCurrentUserId(userId);
        } else {
          console.error('Invalid user ID format:', user.id);
          setCurrentUserId(null);
          navigate('/login');
        }
      } else {
        console.error('User ID is undefined or null:', user);
        setCurrentUserId(null);
        navigate('/login');
      }
    } else {
      navigate('/login');
    }
  }, [user, navigate, setCurrentUserId]);

  /**
   * --------------------------------------------------
   * Fetch ACCEPTED connections via apiFetch
   * Endpoint: GET /api/connections/accepted
   * Returns: { "acceptedConnections": [ { "id": ..., "name": ... }, ... ] }
   * --------------------------------------------------
   */
  useEffect(() => {
    if (!user || !user.id) return; // Only fetch if user is valid

    async function fetchAcceptedConnections() {
      try {
        // Use apiFetch instead of fetch
        const data = await apiFetch('connections/accepted', {
          method: 'GET',
        });
        // data should look like { acceptedConnections: [...] }

        if (Array.isArray(data.acceptedConnections)) {
          // Format for MindMap if needed
          const formatted = data.acceptedConnections.map((conn) => ({
            id: conn.id,
            name: conn.name,
          }));
          setConnectedUsers(formatted);
        } else {
          console.warn('No acceptedConnections array found in response', data);
          setConnectedUsers([]);
        }
      } catch (err) {
        console.error('Failed to load accepted connections:', err);
        setConnectedUsers([]);
      }
    }

    fetchAcceptedConnections();
  }, [user]);

  /**
   * --------------------------------------------------
   * Create the centralUser object for MindMap
   * --------------------------------------------------
   */
  const centralUser = {
    id: currentUserId || 1,
    name:
      user && user.firstName
        ? `${user.firstName} ${user.lastName || ''}`
        : 'Me',
  };

  /**
   * --------------------------------------------------
   * Handle Feed Image Upload
   * --------------------------------------------------
   */
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedExtensions = [
        'png',
        'jpg',
        'jpeg',
        'gif',
        'bmp',
        'webp',
        'heic',
        'heif',
      ];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        alert(
          `Unsupported file type. Allowed types: ${allowedExtensions.join(', ')}.`
        );
        return;
      }

      try {
        const compressedFile = await compressImage(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });

        setNewPostImageFile(compressedFile);
        setNewPostImagePreview(URL.createObjectURL(compressedFile));
      } catch (error) {
        console.error('Image compression failed:', error);
        alert(
          'Failed to compress the image. Please try a different image or try again.'
        );
      }
    }
  };

  const handleRemoveImage = () => {
    if (newPostImagePreview) {
      URL.revokeObjectURL(newPostImagePreview);
    }
    setNewPostImageFile(null);
    setNewPostImagePreview(null);
  };

  // Clean up image URLs
  useEffect(() => {
    return () => {
      if (newPostImagePreview) {
        URL.revokeObjectURL(newPostImagePreview);
      }
    };
  }, [newPostImagePreview]);

  /**
   * --------------------------------------------------
   * Handle Posting a New Feed Item
   * --------------------------------------------------
   */
  const handlePostFeedItem = useCallback(async () => {
    if (!newPostContent.trim()) {
      console.warn('Post content is empty.');
      return;
    }
    setIsPosting(true);

    let imageUrl = null;
    if (newPostImageFile) {
      try {
        const formData = new FormData();
        const fileName = newPostImageFile.name || 'image.jpg';
        formData.append('image', newPostImageFile, fileName);

        const uploadData = await apiFetch('upload/post-image', {
          method: 'POST',
          body: formData,
        });
        imageUrl = uploadData.imageUrl;
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
        setIsPosting(false);
        return;
      }
    }

    const postPayload = {
      content: newPostContent.trim(),
      imageUrl: imageUrl || '',
    };

    try {
      const data = await apiFetch('feed', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: postPayload,
        credentials: 'include',
      });

      if (data.feedItem) {
        setFeedItems((prevFeed) => {
          const exists = prevFeed.some(
            (item) => item.id === data.feedItem.id
          );
          return exists ? prevFeed : [data.feedItem, ...prevFeed];
        });
        setSuccessMessage('Post created successfully!');
      }

      setNewPostContent('');
      setNewPostImageFile(null);
      setNewPostImagePreview(null);
      setIsPosting(false);
    } catch (error) {
      console.error('Error posting new feed item:', error);
      setIsPosting(false);
      setError(
        error.message || 'Failed to post the feed item. Please try again.'
      );
    }
  }, [newPostContent, newPostImageFile, setFeedItems]);

  /**
   * --------------------------------------------------
   * Comment Toggling
   * --------------------------------------------------
   */
  const handleToggleComments = useCallback((feedItemId) => {
    setVisibleComments((prevState) => ({
      ...prevState,
      [feedItemId]: !prevState[feedItemId],
    }));
  }, []);

  /**
   * --------------------------------------------------
   * Like / Unlike Logic
   * --------------------------------------------------
   */
  const toggleLike = useCallback(
    async (feedItemId, isCurrentlyLikedByUser) => {
      if (!user) {
        alert('You must be logged in to like posts.');
        return;
      }

      const endpoint = `shared-feed/like/${feedItemId}`;
      const method = isCurrentlyLikedByUser ? 'DELETE' : 'POST';

      try {
        await apiFetch(endpoint, { method, credentials: 'include' });

        // Update normal feed
        setFeedItems((prev) =>
          prev.map((item) =>
            item.id === feedItemId
              ? {
                  ...item,
                  likedByCurrentUser: !isCurrentlyLikedByUser,
                  likesCount: isCurrentlyLikedByUser
                    ? item.likesCount - 1
                    : item.likesCount + 1,
                }
              : item
          )
        );

        // Update live feed
        setLiveFeedItems((prev) =>
          prev.map((item) =>
            item.id === feedItemId
              ? {
                  ...item,
                  likedByCurrentUser: !isCurrentlyLikedByUser,
                  likesCount: isCurrentlyLikedByUser
                    ? item.likesCount - 1
                    : item.likesCount + 1,
                }
              : item
          )
        );
      } catch (error) {
        console.error('Error toggling like:', error);
      }
    },
    [user, setFeedItems, setLiveFeedItems]
  );

  /**
   * --------------------------------------------------
   * Delete Feed Item
   * --------------------------------------------------
   */
  const handleDelete = useCallback(
    async (postId) => {
      const confirmDelete = window.confirm(
        'Are you sure you want to delete this post?'
      );
      if (!confirmDelete) return;

      try {
        await apiFetch(`posts/${postId}`, {
          method: 'DELETE',
          credentials: 'include',
        });

        setFeedItems((prevFeedItems) =>
          prevFeedItems.filter((item) => item.id !== postId)
        );
        setLiveFeedItems((prevLiveFeed) =>
          prevLiveFeed.filter((item) => item.id !== postId)
        );
      } catch (error) {
        console.error('Error deleting post:', error);
        alert('Failed to delete the post. Please try again.');
      }
    },
    [setFeedItems, setLiveFeedItems]
  );

  /**
   * --------------------------------------------------
   * Close Popups on Escape
   * --------------------------------------------------
   */
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        setShowActivitiesPopup(false);
        setShowNotificationsPopup(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  /**
   * --------------------------------------------------
   * Activity Search & Filter
   * --------------------------------------------------
   */
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value.toLowerCase());
  }, []);

  const filteredActivities = activities.filter((activity) =>
    activity.description.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="dashboard-page">
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>

      <div className="dashboard-container">
        <div className="main-content" id="main-content" tabIndex="-1">
          {/* Tabs */}
          <div className="feeds-tabs" role="tablist" aria-label="Feed Tabs">
            <button
              className={`tab-button ${activeTab === 'feed' ? 'active' : ''}`}
              onClick={() => setActiveTab('feed')}
              aria-selected={activeTab === 'feed'}
              role="tab"
              id="tab-feed"
              aria-controls="feed"
            >
              Feed
            </button>
            <button
              className={`tab-button ${
                activeTab === 'live-feed' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('live-feed')}
              aria-selected={activeTab === 'live-feed'}
              role="tab"
              id="tab-live-feed"
              aria-controls="live-feed"
            >
              Live Feed
            </button>

            {/* Mind Map Tab */}
            <button
              className={`tab-button ${
                activeTab === 'mind-map' ? 'active' : ''
              }`}
              onClick={() => setActiveTab('mind-map')}
              aria-selected={activeTab === 'mind-map'}
              role="tab"
              id="tab-mind-map"
              aria-controls="mind-map"
            >
              Mind Map
            </button>
          </div>

          <div className="feeds-container">
            {/* Feed Section */}
            {activeTab === 'feed' && (
              <FeedSection
                feedItems={feedItems}
                setFeedItems={setFeedItems}
                newPostContent={newPostContent}
                setNewPostContent={setNewPostContent}
                newPostImageFile={newPostImageFile}
                newPostImagePreview={newPostImagePreview}
                isPosting={isPosting}
                handleImageChange={handleImageChange}
                handleRemoveImage={handleRemoveImage}
                handlePostFeedItem={handlePostFeedItem}
                error={error}
                successMessage={successMessage}
                toggleLike={toggleLike}
                handleToggleComments={handleToggleComments}
                visibleComments={visibleComments}
                currentUserId={currentUserId}
                onDelete={handleDelete}
                cacheBustValues={cacheBustValues}
              />
            )}

            {/* Live Feed Section */}
            {activeTab === 'live-feed' && (
              <LiveFeedSection
                liveFeedItems={liveFeedItems}
                toggleLike={toggleLike}
                handleToggleComments={handleToggleComments}
                visibleComments={visibleComments}
                currentUserId={currentUserId}
                onDelete={handleDelete}
                cacheBustValues={cacheBustValues}
              />
            )}

            {/* Mind Map Section */}
            {activeTab === 'mind-map' && (
              <div
                className="mind-map-section"
                role="tabpanel"
                aria-labelledby="tab-mind-map"
              >
                <h2>Mind Map</h2>
                <MindMap
                  centralUser={centralUser}
                  connectedUsers={connectedUsers}
                />
              </div>
            )}
          </div>
        </div>

        {/* Activities Popup */}
        {showActivitiesPopup && (
          <div
            className="activities-popup"
            role="dialog"
            aria-modal="true"
            aria-labelledby="activities-title"
          >
            <div
              className="popup-overlay"
              onClick={() => setShowActivitiesPopup(false)}
              aria-label="Close Activities Popup"
            />
            <div className="popup-content" tabIndex="-1">
              <button
                className="close-popup tooltip"
                onClick={() => setShowActivitiesPopup(false)}
                aria-label="Close Activities Popup"
                data-tooltip="Close"
              >
                &times;
              </button>
              <h2 id="activities-title">Your Recent Activities</h2>
              <div className="popup-controls">
                <input
                  type="text"
                  placeholder="Search activities..."
                  value={searchTerm}
                  onChange={handleSearch}
                  aria-label="Search activities"
                />
                <button
                  onClick={() => {
                    // Could refresh user activities here if desired
                  }}
                  aria-label="Refresh activities"
                  className="tooltip"
                  data-tooltip="Refresh Activities"
                >
                  🔄
                </button>
              </div>
              <ul className="activities-list">
                {filteredActivities.map((activity, index) => (
                  <li key={index}>
                    <span className="activity-icon" aria-hidden="true">
                      {activity.description.includes('Logged in')
                        ? '🔑'
                        : activity.description.includes('Logged out')
                        ? '🔒'
                        : '📄'}
                    </span>
                    <span>
                      {new Date(activity.timestamp).toLocaleString()}: {activity.description}
                    </span>
                  </li>
                ))}
                {filteredActivities.length === 0 && <li>No activities found.</li>}
              </ul>
            </div>
          </div>
        )}

        {/* Notifications Popup */}
        {showNotificationsPopup && (
          <div
            className="notifications-popup"
            role="dialog"
            aria-modal="true"
            aria-labelledby="notifications-title"
          >
            <div
              className="popup-overlay"
              onClick={() => setShowNotificationsPopup(false)}
              aria-label="Close Notifications Popup"
            />
            <div className="popup-content" tabIndex="-1">
              <button
                className="close-popup tooltip"
                onClick={() => setShowNotificationsPopup(false)}
                aria-label="Close Notifications Popup"
                data-tooltip="Close"
              >
                &times;
              </button>
              <h2 id="notifications-title">Notifications</h2>
              <ul className="notifications-list">
                {notifications.length === 0 ? (
                  <li>No notifications.</li>
                ) : (
                  notifications.map((notification, index) => (
                    <li key={index} className={notification.read ? '' : 'unread'}>
                      <span>{notification.message}</span>
                      <span className="notification-timestamp">
                        {new Date(notification.timestamp).toLocaleString()}
                      </span>
                      {!notification.read && (
                        <button
                          onClick={() => {
                            setNotifications((prevNotifications) =>
                              prevNotifications.map((notif, notifIndex) =>
                                notifIndex === index ? { ...notif, read: true } : notif
                              )
                            );
                          }}
                          aria-label="Mark as read"
                        >
                          Mark as read
                        </button>
                      )}
                    </li>
                  ))
                )}
              </ul>
              {notifications.length > 0 && (
                <button
                  onClick={() => {
                    setNotifications((prevNotifications) =>
                      prevNotifications.map((notif) => ({ ...notif, read: true }))
                    );
                  }}
                  className="mark-all-read-button tooltip"
                  aria-label="Mark all as read"
                  data-tooltip="Mark all as read"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
