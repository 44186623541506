import React, { useCallback, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { WebSocketContext } from '../../context/WebSocketContext';
import { apiFetch } from '../../utils/api';
import FeedItem from './FeedItem';

function LiveFeedSection({
  liveFeedItems,
  toggleLike,
  handleToggleComments,
  visibleComments,
  currentUserId,
  onDelete,
  cacheBustValues
}) {
  const { user } = useContext(AuthContext);
  const { setLiveFeedItems } = useContext(WebSocketContext);

  const [isLiveFeedLoading, setIsLiveFeedLoading] = useState(false);

  // Fetch live feed items from your server
  const fetchLiveFeedItems = useCallback(async () => {
    setIsLiveFeedLoading(true);
    try {
      const data = await apiFetch('live-feed', { credentials: 'include' });
      if (Array.isArray(data)) {
        // Make sure `data` includes a `likedByCurrentUser` field for each item
        setLiveFeedItems(data);
      } else {
        console.error('Unexpected live feed data format:', data);
        setLiveFeedItems([]);
      }
    } catch (error) {
      console.error('Error fetching live feed items:', error);
      setLiveFeedItems([]);
    } finally {
      setIsLiveFeedLoading(false);
    }
  }, [setLiveFeedItems]);

  useEffect(() => {
    if (user) {
      fetchLiveFeedItems();
    }
  }, [user, fetchLiveFeedItems]);

  return (
    <div
      className="live-feed-section"
      id="live-feed"
      role="tabpanel"
      aria-labelledby="tab-live-feed"
    >
      <h2>Live Feed</h2>

      <div className="live-feed-items">
        {isLiveFeedLoading ? (
          <p>Loading live feed...</p>
        ) : liveFeedItems.length === 0 ? (
          <p>No live feed items to display.</p>
        ) : (
          liveFeedItems.map((item) => {
            // Example: Adjust the authorAvatarUrl to add cache-busting if needed
            const userIdStr = String(item.authorId);
            const cacheBustValue = cacheBustValues[userIdStr] || '';
            const baseUrl = item.authorAvatarUrl || '/default-avatar.jpg';
            const separator = baseUrl.includes('?') ? '&' : '?';
            const finalAvatarUrl = cacheBustValue
              ? `${baseUrl}${separator}cacheBust=${cacheBustValue}`
              : baseUrl;

            return (
              <FeedItem
                key={item.id}
                item={{
                  ...item,
                  authorAvatarUrl: finalAvatarUrl,
                  // Make sure this object includes "likedByCurrentUser"
                  // if your backend is returning that field.
                }}
                toggleLike={toggleLike}
                handleToggleComments={handleToggleComments}
                visibleComments={visibleComments}
                onDelete={onDelete}
                showLikeButton={true}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

export default LiveFeedSection;
