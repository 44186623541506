import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { apiFetch } from '../utils/api'; 
import MindMap from './mindmap/MindMap';

function MindMapPage() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useContext(AuthContext);

  // Track accepted connections
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (authLoading) return;
    if (!user) {
      // Not logged in
      navigate('/login');
      return;
    }

    // Fetch accepted connections from the same endpoint as AcceptedConnections
    async function fetchConnections() {
      try {
        const data = await apiFetch('connections/accepted', { method: 'GET' });
        // data.acceptedConnections might hold multiple connections
        // For MindMap, we only need { id, name }, but you can also store email, avatarUrl, etc.
        const mapped = data.acceptedConnections.map((conn) => ({
          id: conn.id,
          name: conn.name,
        }));
        setConnectedUsers(mapped);
      } catch (err) {
        console.error('Error fetching accepted connections:', err);
        setError(err.message);
        if (err.message === 'Unauthorized') {
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    }

    fetchConnections();
  }, [user, authLoading, navigate]);

  if (loading) {
    return <p>Loading Mind Map...</p>;
  }

  if (error) {
    return <p style={{ color: 'red' }}>Error: {error}</p>;
  }

  if (connectedUsers.length === 0) {
    return <p>You have no accepted connections yet.</p>;
  }

  // Build the central user object 
  const centralUser = {
    id: user?.id || 1,
    name: user?.name || user?.firstName || 'Me',
  };

  return (
    <div>
      <h2>My MindMap of Accepted Connections</h2>
      <MindMap centralUser={centralUser} connectedUsers={connectedUsers} />
    </div>
  );
}

export default MindMapPage;
