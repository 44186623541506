import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Inbox.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css/animate.min.css'; // Optional if you still want subtle animations
import { apiFetch } from '../utils/api';

function Inbox() {
  const [inboxMessages, setInboxMessages] = useState([]);
  const [sentMessages, setSentMessages] = useState([]);
  const [mergedMessages, setMergedMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [actionFeedback, setActionFeedback] = useState({ type: '', message: '' });
  const navigate = useNavigate();
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL;
  const ws = useRef(null);
  const reconnectTimer = useRef(null);
  const reconnectInterval = 5000;

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await apiFetch('user-info');
        setUserEmail(data.email);
      } catch (error) {
        console.error('Error fetching user info:', error);
        if (error.status === 401) {
          navigate('/login');
        }
        setActionFeedback({
          type: 'error',
          message: 'Error fetching user information.',
        });
      }
    };

    fetchUserInfo();
  }, [navigate]);

  useEffect(() => {
    if (!userEmail) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchInboxMessages(), fetchSentMessages()]);
      } catch (error) {
        // Handled within respective functions
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    setupWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      if (reconnectTimer.current) {
        clearTimeout(reconnectTimer.current);
      }
    };
  }, [userEmail]);

  useEffect(() => {
    const combined = [
      ...inboxMessages.map((msg) => ({ ...msg, type: 'received' })),
      ...sentMessages.map((msg) => ({ ...msg, type: 'sent' })),
    ];

    combined.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    setMergedMessages(combined);
  }, [inboxMessages, sentMessages]);

  const groupedMessages = useMemo(() => {
    const groups = {};

    mergedMessages.forEach((msg) => {
      const otherPartyEmail =
        msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail;

      if (!otherPartyEmail) return;

      if (!groups[otherPartyEmail]) {
        groups[otherPartyEmail] = {
          userEmail: otherPartyEmail,
          messages: [],
        };
      }

      groups[otherPartyEmail].messages.push(msg);
    });

    // Sort groups by latest message timestamp
    return Object.values(groups).sort((a, b) => {
      const aLatest = new Date(a.messages[0].timestamp);
      const bLatest = new Date(b.messages[0].timestamp);
      return bLatest - aLatest;
    });
  }, [mergedMessages]);

  const fetchInboxMessages = useCallback(async () => {
    try {
      const data = await apiFetch('messages/inbox');
      setInboxMessages(data.messages || []);
    } catch (error) {
      console.error('Error fetching inbox messages:', error);
      if (error.status === 401) {
        navigate('/login');
      }
      setActionFeedback({ type: 'error', message: 'Error fetching inbox messages.' });
    }
  }, [navigate]);

  const fetchSentMessages = useCallback(async () => {
    try {
      const data = await apiFetch('messages/sent');
      setSentMessages(data.messages || []);
    } catch (error) {
      console.error('Error fetching sent messages:', error);
      if (error.status === 401) {
        navigate('/login');
      }
      setActionFeedback({ type: 'error', message: 'Error fetching sent messages.' });
    }
  }, [navigate]);

  const setupWebSocket = useCallback(() => {
    if (!websocketUrl) {
      console.error('WebSocket URL is not defined.');
      setActionFeedback({ type: 'error', message: 'WebSocket URL is not defined.' });
      return;
    }

    ws.current = new WebSocket(websocketUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connection established.');
    };

    ws.current.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        handleWebSocketMessage(data);
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setActionFeedback({ type: 'error', message: 'WebSocket encountered an error.' });
    };

    ws.current.onclose = (event) => {
      console.warn('WebSocket connection closed:', event);
      setActionFeedback({
        type: 'info',
        message: 'WebSocket connection closed. Attempting to reconnect...',
      });
      reconnectTimer.current = setTimeout(() => {
        setupWebSocket();
      }, reconnectInterval);
    };
  }, [websocketUrl]);

  const handleWebSocketMessage = useCallback(
    (data) => {
      switch (data.type) {
        case 'new_message': {
          const newMsg = data.payload;
          const type = newMsg.senderEmail === userEmail ? 'sent' : 'received';

          setMergedMessages((prevMessages) => {
            const exists = prevMessages.some((msg) => msg.id === newMsg.id);
            if (exists) return prevMessages;
            return [{ ...newMsg, type }, ...prevMessages];
          });

          setActionFeedback({
            type: 'info',
            message: `New message ${type === 'sent' ? 'sent' : 'received'}.`,
          });
          break;
        }
        default:
          console.warn('Unknown WebSocket message type:', data.type);
      }
    },
    [userEmail]
  );

  const handleViewMessage = (message) => {
    setSelectedMessage(message);
    setShowModal(true);

    // Mark as read if it's a received message
    if (message.type === 'received' && !message.isRead) {
      markAsRead(message.id);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedMessage(null);
    setShowReplyForm(false);
    setReplyContent('');
  };

  const markAsRead = async (messageId) => {
    try {
      await apiFetch(`messages/${messageId}/read`, {
        method: 'PUT',
      });

      setMergedMessages((prev) =>
        prev.map((msg) =>
          msg.id === messageId ? { ...msg, isRead: true } : msg
        )
      );
      setActionFeedback({ type: 'success', message: 'Message marked as read.' });
    } catch (error) {
      console.error(error);
      setActionFeedback({ type: 'error', message: 'Error marking message as read.' });
    }
  };

  const toggleReadStatus = async (messageId, currentStatus) => {
    const method = currentStatus ? 'DELETE' : 'PUT'; // DELETE -> Mark as Unread

    try {
      await apiFetch(`messages/${messageId}/read`, { method });
      setMergedMessages((prev) =>
        prev.map((msg) =>
          msg.id === messageId ? { ...msg, isRead: !currentStatus } : msg
        )
      );
      setActionFeedback({
        type: 'success',
        message: `Message marked as ${!currentStatus ? 'read' : 'unread'}.`,
      });
    } catch (error) {
      console.error(error);
      setActionFeedback({ type: 'error', message: 'Error updating read status.' });
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!window.confirm('Are you sure you want to delete this message?')) {
      return;
    }

    try {
      await apiFetch(`messages/${messageId}`, { method: 'DELETE' });
      setMergedMessages((prev) => prev.filter((msg) => msg.id !== messageId));
      setActionFeedback({
        type: 'success',
        message: 'Message deleted successfully.',
      });
    } catch (error) {
      console.error('Error deleting message:', error);
      setActionFeedback({ type: 'error', message: 'Error deleting message.' });
    }
  };

  const handleReply = () => {
    setShowReplyForm(true);
  };

  const handleReplySubmit = async (e) => {
    e.preventDefault();
    if (!replyContent.trim()) {
      setActionFeedback({
        type: 'error',
        message: 'Reply content cannot be empty.',
      });
      return;
    }

    const recipientEmail =
      selectedMessage.type === 'received'
        ? selectedMessage.senderEmail
        : selectedMessage.recipientEmail;
    if (!recipientEmail) {
      setActionFeedback({
        type: 'error',
        message: 'Recipient email is missing.',
      });
      return;
    }

    setIsSending(true);

    try {
      await apiFetch('messages/send', {
        method: 'POST',
        body: {
          recipientEmail: recipientEmail,
          content: replyContent.trim(),
        },
      });

      setActionFeedback({ type: 'success', message: 'Reply sent successfully.' });
      setShowReplyForm(false);
      setReplyContent('');
      fetchSentMessages();
    } catch (error) {
      console.error('Error sending reply:', error);
      setActionFeedback({ type: 'error', message: 'Error sending reply.' });
    } finally {
      setIsSending(false);
    }
  };

  const renderFeedbackAnimation = () => {
    if (!actionFeedback.type) return null;

    let animationClass = 'animate__fadeIn';
    switch (actionFeedback.type) {
      case 'error':
        animationClass = 'animate__shakeX';
        break;
      case 'info':
        animationClass = 'animate__fadeIn';
        break;
      case 'success':
        animationClass = 'animate__fadeInDown';
        break;
      default:
        animationClass = 'animate__fadeIn';
    }

    return (
      <div className={`feedback-message animate__animated ${animationClass}`}>
        <p>{actionFeedback.message}</p>
      </div>
    );
  };

  return (
    <div className="inbox-page">
      <ToastContainer />

      {isLoading ? (
        <div className="loading-container animate__animated animate__fadeIn">
          <div className="spinner"></div>
          <p>Loading messages...</p>
        </div>
      ) : groupedMessages.length === 0 ? (
        <p className="no-messages animate__animated animate__fadeIn">
          No messages found.
        </p>
      ) : (
        <div className="grouped-message-list">
          {groupedMessages.map((group) => (
            <div key={group.userEmail} className="message-group">
              <h3 className="group-header">{group.userEmail}</h3>
              <ul className="message-list">
                {group.messages.map((msg) => (
                  <li
                    key={msg.id}
                    className={`message-item ${msg.isRead ? 'read' : 'unread'} ${
                      msg.type === 'sent' ? 'sent' : 'received'
                    } animate__animated animate__fadeIn`}
                    onClick={() => handleViewMessage(msg)}
                    tabIndex="0"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleViewMessage(msg);
                    }}
                    aria-label={`Message ${msg.type === 'sent' ? 'sent to' : 'received from'} ${
                      msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail
                    }`}
                  >
                    <div className="message-summary">
                      <div className="message-from">
                        <strong>{msg.type === 'sent' ? 'To:' : 'From:'}</strong>{' '}
                        {msg.type === 'sent' ? msg.recipientEmail : msg.senderEmail}
                      </div>
                      <div className="message-content">
                        <strong>Content:</strong>{' '}
                        {msg.content.length > 50
                          ? `${msg.content.substring(0, 50)}...`
                          : msg.content}
                      </div>
                      <div className="message-date">
                        <strong>Date:</strong>{' '}
                        {new Date(msg.timestamp).toLocaleString()}
                      </div>
                      {msg.type === 'received' && !msg.isRead && (
                        <span className="new-badge">New</span>
                      )}
                      {msg.type === 'sent' && (
                        <span
                          className={`read-status ${
                            msg.isRead ? 'read' : 'unread-recipient'
                          }`}
                        >
                          {msg.isRead
                            ? 'Read by Recipient'
                            : 'Unread by Recipient'}
                        </span>
                      )}
                    </div>

                    <div className="message-actions">
                      {msg.type === 'received' && (
                        <button
                          className="mark-read-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleReadStatus(msg.id, msg.isRead);
                          }}
                          aria-label={`Mark message as ${
                            msg.isRead ? 'unread' : 'read'
                          }`}
                        >
                          {msg.isRead ? 'Mark Unread' : 'Mark Read'}
                        </button>
                      )}
                      <button
                        className="delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteMessage(msg.id);
                        }}
                        aria-label="Delete message"
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {renderFeedbackAnimation()}

      {showModal && selectedMessage && (
        <div
          className="modal-overlay"
          onClick={handleCloseModal}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div
            className="modal-content animate__animated animate__fadeInDown"
            onClick={(e) => e.stopPropagation()}
          >
            <h3 id="modal-title">
              {selectedMessage.type === 'sent'
                ? `Message to ${selectedMessage.recipientEmail}`
                : `Message from ${selectedMessage.senderEmail}`}
            </h3>
            <p className="modal-message-content">{selectedMessage.content}</p>
            <div className="modal-actions">
              {selectedMessage.type === 'received' && (
                <button
                  className="reply-button"
                  onClick={handleReply}
                  aria-label="Reply to message"
                >
                  Reply
                </button>
              )}
              <button
                className="close-modal-button"
                onClick={handleCloseModal}
                aria-label="Close modal"
              >
                Close
              </button>
            </div>

            {showReplyForm && (
              <form
                className="reply-form animate__animated animate__fadeInUp"
                onSubmit={handleReplySubmit}
              >
                <label htmlFor="replyContent">Your Reply:</label>
                <textarea
                  id="replyContent"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  required
                  placeholder="Type your reply..."
                ></textarea>
                <button
                  type="submit"
                  className="send-reply-button"
                  aria-label="Send reply"
                  disabled={isSending}
                >
                  {isSending ? <div className="spinner small"></div> : 'Send Reply'}
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Inbox;
