import React from 'react';
import PropTypes from 'prop-types';
import './MindMap.css';

function MindMap({ centralUser, connectedUsers }) {
  const width = 600;
  const height = 600;
  const centerX = width / 2;
  const centerY = height / 2;
  const radius = 150;

  return (
    <div className="mindmap-container">
      <svg width={width} height={height}>
        {/* Central user node */}
        <g className="node-group central-node">
          <circle cx={centerX} cy={centerY} r="30" className="central-node-circle" />
          <text
            x={centerX}
            y={centerY + 5}
            textAnchor="middle"
            className="central-label"
          >
            {centralUser.name}
          </text>
        </g>

        {/* Connected users */}
        {connectedUsers.map((user, index) => {
          const angle = (2 * Math.PI / connectedUsers.length) * index;
          const offsetX = Math.random() * 20 - 10;
          const offsetY = Math.random() * 20 - 10;

          const userX = centerX + (radius + offsetX) * Math.cos(angle);
          const userY = centerY + (radius + offsetY) * Math.sin(angle);

          return (
            <g className="node-group" key={user.id}>
              <line
                x1={centerX}
                y1={centerY}
                x2={userX}
                y2={userY}
                className="connection-line"
              />
              <circle
                cx={userX}
                cy={userY}
                r="20"
                className="user-node-circle"
              />
              <text
                x={userX}
                y={userY - 30}
                textAnchor="middle"
                className="user-label"
              >
                {user.name}
              </text>
            </g>
          );
        })}
      </svg>
    </div>
  );
}

MindMap.propTypes = {
  centralUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  connectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MindMap;
