// FeedSection.js
import React, { useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { WebSocketContext } from '../../context/WebSocketContext';
import { apiFetch } from '../../utils/api';
import FeedItem from './FeedItem';

function FeedSection({
  feedItems,
  setFeedItems,
  newPostContent,
  setNewPostContent,
  newPostImageFile,
  newPostImagePreview,
  isPosting,
  handleImageChange,
  handleRemoveImage,
  handlePostFeedItem,   // <-- This is your fully implemented callback from Dashboard.js
  error,
  successMessage,
  toggleLike,
  handleToggleComments,
  visibleComments,
  currentUserId,
  onDelete,
  cacheBustValues
}) {
  const { user } = useContext(AuthContext);
  const { /* other context values if needed */ } = useContext(WebSocketContext);

  // (Optional) fetch feed items here if not already done in Dashboard
  const fetchFeedItems = useCallback(async () => {
    try {
      const data = await apiFetch('feed', { credentials: 'include' });
      if (Array.isArray(data)) {
        setFeedItems(data);
      } else {
        console.error('Unexpected feed data format:', data);
        setFeedItems([]);
      }
    } catch (error) {
      console.error('Error fetching feed items:', error);
      setFeedItems([]);
    }
  }, [setFeedItems]);

  useEffect(() => {
    // Only fetch if the user is logged in
    if (user) {
      fetchFeedItems();
    }
  }, [user, fetchFeedItems]);

  return (
    <div className="feed-section" id="feed" role="tabpanel" aria-labelledby="tab-feed">
      <h2>Feed</h2>

      {/* New Post Form */}
      <div className="new-post">
        <textarea
          id="new-post-content"
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
          placeholder="What's on your mind?"
          className="new-post-textarea"
          aria-label="New post content"
          disabled={isPosting}
          required
        />

        <input
          type="file"
          id="new-post-image"
          accept=".png, .jpg, .jpeg, .gif, .bmp, .webp, .heic, .heif"
          onChange={handleImageChange}
          className="new-post-image-input"
          aria-label="Upload image"
          disabled={isPosting}
        />

        {newPostImagePreview && (
          <div className="image-preview">
            <img src={newPostImagePreview} alt="Preview" />
            <button
              onClick={handleRemoveImage}
              className="remove-image-button"
              aria-label="Remove image"
            >
              &times;
            </button>
          </div>
        )}

        {/* Error & success messages */}
        {error && <p className="error-message" role="alert">{error}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <button
          onClick={handlePostFeedItem}
          disabled={!newPostContent.trim() || isPosting}
          className="post-button tooltip"
          aria-label="Create a new post"
          data-tooltip="Create a new post"
        >
          {isPosting ? 'Posting...' : 'Post'}
        </button>
      </div>

      {/* The Feed Items */}
      <div className="feed-items">
        {feedItems.length === 0 ? (
          <p>No feed items to display.</p>
        ) : (
          feedItems.map((item) => {
            // Add cacheBustValue to avatar URL
            const userIdStr = String(item.authorId);
            const cacheBustValue = cacheBustValues[userIdStr] || '';
            const baseUrl = item.avatarUrl || '/default-avatar.jpg';
            const separator = baseUrl.includes('?') ? '&' : '?';
            const finalAvatarUrl = cacheBustValue
              ? `${baseUrl}${separator}cacheBust=${cacheBustValue}`
              : baseUrl;

            const updatedItem = { ...item, avatarUrl: finalAvatarUrl };

            return (
              <FeedItem
                key={updatedItem.id}
                item={updatedItem}
                toggleLike={toggleLike}
                handleToggleComments={handleToggleComments}
                visibleComments={visibleComments}
                currentUserId={currentUserId}
                onDelete={onDelete}
                showLikeButton={false}
              />
            );
          })
        )}
      </div>
    </div>
  );
}

export default FeedSection;
