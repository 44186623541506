import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ConnectionRequests.module.css';
import { AuthContext } from '../context/AuthContext'; // Import AuthContext for authentication
import { WebSocketContext } from '../context/WebSocketContext'; // Import WebSocketContext for cache busting
import { apiFetch } from '../utils/api'; // Import the apiFetch utility
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageWithFallback from '../components/ImageWithFallback'; // Import ImageWithFallback

function ConnectionRequests() {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { user, loading: authLoading } = useContext(AuthContext); // Access user and loading state
  const { cacheBustValues } = useContext(WebSocketContext); // Access cacheBustValues from WebSocketContext

  // Fetch pending connection requests
  const fetchPendingRequests = useCallback(async () => {
    try {
      const data = await apiFetch('connections/pending', {
        method: 'GET',
      });
      setPendingRequests(data.pendingRequests || []);
    } catch (error) {
      console.error('Error fetching pending requests:', error.message);
      toast.error('Error fetching pending connection requests.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Effect to handle authentication and fetch data
  useEffect(() => {
    if (!authLoading) {
      if (!user) {
        // If user is not authenticated, redirect to login
        toast.error('You must be logged in to view connection requests.');
        navigate('/login');
      } else {
        fetchPendingRequests();
      }
    }
  }, [fetchPendingRequests, authLoading, user, navigate]);

  // Handle Approving a Connection Request
  const handleApproveRequest = useCallback(
    async (id) => {
      try {
        await apiFetch('connections/approve', {
          method: 'POST',
          body: { requesterId: Number(id) },
        });
        setPendingRequests((prev) => prev.filter((request) => request.requesterId !== id));
        toast.success('Connection request approved successfully!');
      } catch (error) {
        console.error('Error approving connection request:', error.message);
        toast.error(`Error approving request: ${error.message}`);
        if (error.errorCode === 'UNAUTHORIZED' || error.message === 'Unauthorized') {
          navigate('/login');
        }
      }
    },
    [navigate]
  );

  // Handle Declining a Connection Request
  const handleDeclineRequest = useCallback(
    async (id) => {
      try {
        await apiFetch('connections/decline', {
          method: 'POST',
          body: { requesterId: Number(id) },
        });
        setPendingRequests((prev) => prev.filter((request) => request.requesterId !== id));
        toast.success('Connection request declined successfully!');
      } catch (error) {
        console.error('Error declining connection request:', error.message);
        toast.error(`Error declining request: ${error.message}`);
        if (error.errorCode === 'UNAUTHORIZED' || error.message === 'Unauthorized') {
          navigate('/login');
        }
      }
    },
    [navigate]
  );

  // Render loading state
  if (isLoading || authLoading) {
    return (
      <div className={styles.connectionRequestsPage}>
        <div className={styles.loading}>
          <div className={styles.spinner}></div>
          <p>Loading pending connection requests...</p>
        </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  // Render no pending requests
  if (pendingRequests.length === 0) {
    return (
      <div className={styles.connectionRequestsPage}>
        <header className={styles.connectionRequestsHeader}>
          <h1>Pending Connection Requests</h1>
        </header>
        <p>No pending connection requests.</p>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
      </div>
    );
  }

  return (
    <div className={styles.connectionRequestsPage}>
      <header className={styles.connectionRequestsHeader}>
        <h1>Pending Connection Requests</h1>
      </header>

      <ul className={styles.pendingRequestsList}>
        {pendingRequests.map((request) => {
          const cacheBustValue = cacheBustValues[String(request.requesterId)] || '';

          // Modify how we build the URL with cache busting
          const baseUrl = request.avatarUrl || '/default-avatar.jpg';
          const separator = baseUrl.includes('?') ? '&' : '?';
          const imageUrl = cacheBustValue ? `${baseUrl}${separator}cacheBust=${cacheBustValue}` : baseUrl;

          return (
            <li key={request.requesterId} className={styles.pendingRequestItem}>
              <div className={styles.requesterInfo}>
                <ImageWithFallback
                  src={imageUrl}
                  fallbackSrc="/default-avatar.jpg"
                  alt={`${request.requesterName}'s avatar`}
                  className={styles.requesterAvatar}
                />
                <div className={styles.requesterDetails}>
                  <h3>{request.requesterName}</h3>
                  <p>{request.requesterEmail || 'Email not available'}</p>
                  <p className={styles.requestTimestamp}>
                    Requested on: {new Date(request.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className={styles.requestActions}>
                <button
                  className={styles.approveButton}
                  onClick={() => handleApproveRequest(request.requesterId)}
                  aria-label={`Approve request from ${request.requesterName}`}
                >
                  ✅ Approve
                </button>
                <button
                  className={styles.declineButton}
                  onClick={() => handleDeclineRequest(request.requesterId)}
                  aria-label={`Decline request from ${request.requesterName}`}
                >
                  ❌ Decline
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
}

export default ConnectionRequests;
